import React from 'react'

import Layout from '../components/layout'

const NotFound = () => {
  return (
    <Layout title="Page not found">
      <article>
        <h1>This page does not exist</h1>
      </article>
    </Layout>
  )
}
export default NotFound
